import React, { useRef, useEffect } from "react"
import NProgress from "nprogress"

let key = 0
const style = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
}
const WithProgress = ({ status, children }) => {
    const id = useRef()
    const captured = useRef()
    if (!id.current) {
        id.current = `progress-${key}`
        key++
    }

    useEffect(() => {
        if (status) {
            captured.current = NProgress.settings.parent
            NProgress.configure({ parent: `#${id.current}` })
            NProgress.start()
        } else {
            NProgress.done()
            NProgress.configure({ parent: captured.current })
        }
    }, [status])
    useEffect(() => {
        return () => {
            if (captured.current) NProgress.configure({ parent: captured.current })
            if (NProgress.isStarted()) NProgress.done()
        }
    }, [])

    return (
        <div id={id.current} style={style}>
            {children}
        </div>
    )
}
export default WithProgress
