import NProgress from "nprogress"

const stack = []

const start = () => {
    if (typeof window === "undefined") return
    stack.push(1)
    //if (stack.length===1) NProgress.start()
}

const set = step => {
    if (typeof window === "undefined") return
    NProgress.set(step)
}

const done = () => {
    if (typeof window === "undefined") return
    stack.pop()
    if (stack.length === 0) NProgress.done()
}

const reparent = (parent = "body") => {
    if (typeof window === "undefined") return
    NProgress.configure({ parent })
}

export default { start, set, done, reparent }
