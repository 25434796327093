export const initialState = {
    stage: "LIST",
    roll: null,
    weight: "",
}

export const reducer = (state, action) => {
    //console.log(action)
    switch (action.type) {
        case "select-roll":
            return { ...state, roll: action.roll, stage: "MASS" }
        case "add-digit":
            return { ...state, weight: `${state.weight}${action.digit}` }
        case "backspace":
            return {
                ...state,
                weight:
                    state.weight.length > 0
                        ? state.weight.substring(0, state.weight.length - 1)
                        : state.weight,
            }
        case "print":
            return { ...state, stage: "PRINT" }
        case "printing":
            return { ...state, stage: "PRINTING" }
        case "print-end":
            return { ...initialState }
        case "cancel":
            return { ...initialState }
        default:
            return state
    }
}
