import React, { useState, useCallback } from "react"
import { Icon, Link } from "lib"
import MainMenu, { useMainMenu } from "./MainMenu"
const Header = ({ node, language, first }) => {
    const menu = useMainMenu()
    const [open, setOpen] = useState(false)

    const handleToggle = useCallback(() => {
        setOpen(open => !open)
    }, [])
    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])
    const handleActivate = useCallback(() => {
        setOpen(false)
        return false
    }, [])
    const ignore = e => {
        e.stopPropagation()
    }
    return (
        <>
            <header role="banner" id="header" key="header">
                <Link id="logo" to="#">
                    <Icon name="Logo" />
                </Link>

                <div className="header-in">
                    <button id="mobile-menu-button" onClick={handleToggle}>
                        <Icon name="Menu" size={48} />
                    </button>
                    <div className={`menu-wrap ${open ? "menu-open" : ""}`} onClick={handleClose}>
                        <div className="menu-container" onClick={ignore}>
                            <button id="menu-close" onClick={handleClose}>
                                <Icon name="Close" size={48} />
                            </button>
                            <div className="menu-wrap-in">
                                <div>
                                    <MainMenu
                                        id="main-menu"
                                        menu={menu}
                                        language={language}
                                        onActivate={handleActivate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default React.memo(Header)
