import C from "../conf"
import { compose, init, last, set, view, lensPath } from "ramda"
import { pathFromField } from "./util"
import { getFieldInfo } from "./info"

const defaultLanguage = entity =>
    !!C.LANGUAGES ? (!!entity ? entity._lang || C.LANGUAGES[0] : C.LANGUAGES[0]) : null
const currentLanguage = (entity, language) =>
    !!C.LANGUAGES ? (language !== defaultLanguage(entity) ? language : null) : null

const i18nPath = (fieldName, language) => ["_i18n", language, fieldName]

const splitInitLast = field => {
    const path = pathFromField(field)
    return [init(path), last(path)]
}
const geti18n = (entity, field, language) => {
    if (!entity || !field) return null
    const [pathInit, pathLast] = splitInitLast(field)
    const lang = currentLanguage(entity, language)
    const parentLens = lensPath(pathInit)
    const i18nLens = !!lang ? lensPath(i18nPath(pathLast, lang)) : lensPath([pathLast])
    //console.log("GET:", entity, field, pathInit, !!lang ? i18nPath(pathLast, lang) : [pathLast], pathLast)
    //console.log("GET:", entity, field)
    //printTrace(geti18n)
    try {
        const ret = view(compose(parentLens, i18nLens), entity)
        if (ret) {
            //console.log("GET:", field, "RET1:", ret, entity)
            return ret
        }
    } catch {}
    try {
        const fieldLens = lensPath([pathLast])
        const ret = view(compose(parentLens, fieldLens), entity)
        //console.log("GET:", field, "RET2:", ret, entity)
        return ret
    } catch {}
    //console.log("GET:", field, "RET3: null", entity)
    return null
}
const getnoi18n = (entity, field) => {
    if (!entity || !field) return null
    const path = pathFromField(field)
    const fieldPath = lensPath(path)

    try {
        return view(fieldPath, entity)
    } catch {
        return null
    }
}
const seti18n = (parent, field, value, language, parentInfo) => {
    if (!parent || !field) return null
    const [pathInit, pathLast] = splitInitLast(field)
    const lang = currentLanguage(parent, language)
    const parentLens = lensPath(pathInit)

    const fieldInfo = getFieldInfo(parent, field, parentInfo)
    const i18nLens =
        !!lang && fieldInfo._t !== false && fieldInfo.type !== "obj" && fieldInfo.type !== "list"
            ? lensPath(i18nPath(pathLast, lang))
            : lensPath([pathLast])
    /*console.log(
        "SET:",
        entity,
        field,
        pathInit,
        !!lang ? i18nPath(pathLast, lang) : [pathLast],
        pathLast,
        value
    )*/
    try {
        return set(compose(parentLens, i18nLens), value, parent)
        //console.log("RET1:", ret)
        //return entity
    } catch (e) {
        console.log(e)
    }
    return parent
    /*try {
        const fieldLens = lensPath([pathLast])
        const ret = view(compose(parentLens, fieldLens), entity)
        console.log("RET2:", ret)
        return ret
    } catch {}
    console.log("RET3: null")
    return entity*/
}
const setnoi18n = (entity, field, value) => {
    if (!entity || !field) return null

    const fieldPath = lensPath(pathFromField(field))

    try {
        const ret = set(fieldPath, value, entity)
        return ret
    } catch (e) {
        return null
    }
}

const getValue = !!C.LANGUAGES ? geti18n : getnoi18n
const setValue = !!C.LANGUAGES ? seti18n : setnoi18n

export { getValue as get, setValue as set }
