import React from "react"
import { AuthButton } from "lib"

const Footer = ({ node, language }) => {
    if (!node) return null
    return (
        <footer id="footer">
            <div className="footer-in">
                <div className="copyright">Copyright © 2012-2019 Alumetasistem</div>

                <AuthButton />
            </div>
        </footer>
    )
}
export default React.memo(Footer)
