import axios from "axios"
//import wrapInfo from "../conf/wrapInfo"
//import { query, insert, update, queryRefreshAll } from "./api"
import { History } from "../history"
import { Query } from "../query"
import C from "../conf"
//import fieldAdmin from "../conf/fieldAdmin"
import { entityTypes, getTypeInfo } from "./types"
import { get, set } from "./accessors"
import { getFieldInfo, getInfo, getFields, getCollection } from "./info"
import customFieldAdmin from "conf/fieldAdmin"
const fieldAdmin = {}
Object.keys(customFieldAdmin).forEach(key => {
    if (fieldAdmin[key]) fieldAdmin[key] = { ...fieldAdmin[key], ...customFieldAdmin[key] }
    else fieldAdmin[key] = customFieldAdmin[key]
})
const query = q => {
    return axios.post("datamulti", { params: { options: [q] } }).then(data => data.data[0])
}
function* refGenerator(entity, parentFieldName, parent, parentFieldInfo, fields) {
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const value = parent ? get(parent, field.name) : get(entity, field.name)
        const fieldName = parentFieldName ? `${parentFieldName}.${field.name}` : field.name
        if (value) {
            if (field.type === "ref") yield [field, value, fieldName]
            else if (field.fields)
                yield* refGenerator(entity, fieldName, value, field, field.fields)
            else if (field.type === "list") {
                for (let j = 0; j < value.length; j++) {
                    const fName = `${fieldName}.${j}`
                    //const v = entity.getChildValue(parent, parentFieldInfo, `${field.name}.${j}`)
                    const fInfo = getFieldInfo(parent, `${field.name}.${j}`, parentFieldInfo)
                    if (fInfo.type === "ref") yield [fInfo, value[j], fName]
                    else if (fInfo.fields)
                        yield* refGenerator(entity, fName, value[j], fInfo, fInfo.fields)
                }
            }
        }
    }
}
const _updateRefs = (entity, g) => {
    const { value, done } = g.next()
    if (done) return entity

    const [field, val] = value
    if (!val) return _updateRefs(entity, g)

    const refType = entityTypes[field.ref]
    const coll = refType.collection
    /*nodeBundles[field.ref]
        ? "node"
        : refType.collection
        ? refType.collection
        : field.ref*/

    const refItem = {
        coll,
        ref: val.ref,
        dep: entity._id.$oid,
        depColl: getCollection(entity),
    }
    const q = {
        collection: "refs",
        query: refItem,
    }
    //console.log(entity, field, val, refItem)
    return query(q).then(data =>
        !data || !data.results || data.results.length === 0
            ? Query.insert({
                  collection: "refs",
                  data: refItem,
              }).then(() => _updateRefs(entity, g))
            : _updateRefs(entity, g)
    )
}
const updateRefs = entity => {
    return _updateRefs(entity, refGenerator(entity, null, entity, null, getFields(entity)))
}

const _updateDepEntity = (_idRef, entity, depEntity, g, updates, rest) => {
    const { value, done } = g.next()
    //console.log("dep", _idRef, entity, depEntity, updates, value, done)
    if (done) {
        if (updates === 0)
            return axios.delete(`data/refs/${_idRef.$oid}`).then(() => _updateDep(entity, rest))
        else return save(depEntity, null, true, true).then(() => _updateDep(entity, rest))
    }

    const [field, val, fieldName] = value
    const refType = entityTypes[field.ref]
    const collection = refType.collection
    /*nodeBundles[field.ref]
        ? "node"
        : refType.collection
        ? refType.collection
        : field.ref*/
    if (!val || val.ref !== entity._id.$oid || collection !== getCollection(entity))
        return _updateDepEntity(_idRef, entity, depEntity, g, updates, rest)

    const v = { ...val }
    if (field.cache) {
        if (typeof field.cache === "string") {
            field.cache
                .split(",")
                .map(f => f.trim())
                .forEach(f => {
                    v[f] = f === "path" ? entity.path : get(entity, f)
                })
        } else field.cache(v, entity)
    }
    const de = set(depEntity, fieldName, v)

    return _updateDepEntity(_idRef, entity, de, g, updates + 1, rest)
}

const _updateDep = (entity, deps) => {
    if (deps.length === 0) return entity
    const [{ _id, dep, depColl }, ...rest] = deps

    return load(dep, depColl).then(depEntity =>
        _updateDepEntity(
            _id,
            entity,
            depEntity,
            refGenerator(depEntity, null, depEntity, null, getFields(depEntity)),
            0,
            rest
        )
    )
}
const updateDeps = entity => {
    const q = {
        collection: "refs",
        query: {
            ref: entity._id.$oid,
            coll: getCollection(entity),
        },
    }

    return query(q).then(data => {
        //console.log("deps", q, data)
        if (!data || !data.results || data.results.length === 0) return entity
        return _updateDep(entity, data.results)
    })
}

const VerifyException = errors => ({ errors })
const _verifyEntity = entity => {
    let errors = {}
    const info = getInfo(entity)
    if (info.fieldCheck) {
        errors = Object.keys(info.fieldCheck)
            .map(field => ({
                field,
                errors: info.fieldCheck[field][0](entity[field], entity)
                    ? null
                    : { text: info.fieldCheck[field][1] },
            }))
            .filter(item => item.errors)
            .reduce((acc, el) => {
                acc[el.field] = el.errors
                return acc
            }, {})
    }
    info.fields
        .map(field => ({
            field: field.name,
            errors:
                fieldAdmin[field.type] && fieldAdmin[field.type].check
                    ? fieldAdmin[field.type].check[0](entity[field.name])
                        ? null
                        : { text: fieldAdmin[field.type].check[1] }
                    : null,
        }))
        .filter(item => item.errors)
        .forEach(item => {
            if (errors[item.field]) {
                if (errors[item.field].text)
                    errors[item.field].text = `${errors[item.field].text} ${item.errors.text}`
                else errors[item.field].text = item.errors.text
            } else {
                errors[item.field] = item.errors
            }
        })
    if (info.check) {
        const entityErrors = info.check(entity)
        if (entityErrors) {
            errors = { ...errors, ...entityErrors }
        }
    }
    /*
	if (Object.keys(errors).length > 0) {
			dispatch({ type: "errors", errors })
			return false
	}
	return true
	*/
    if (Object.keys(errors).length > 0) throw VerifyException(errors)
    return true
}
const save = (entity, remove, norefs = false, noredirect = false) => {
    return new Promise(resolve => {
        _verifyEntity(entity)

        const oldPath = entity.type === "node" ? entity.path : null
        //entity.presave()
        //console.log(entity)
        const info = getInfo(entity)
        if (entity.type === "node") {
            if (!entity._c) entity._c = { pathauto: true }
            entity._c = { ...entity._c }
            if (entity._c.pathauto === undefined) entity._c.pathauto = true
            if (entity._c && entity._c.pathauto && info.pathinfo) {
                if (typeof info.pathinfo === "string") entity._c.pathinfo = info.pathinfo
                else entity._c.pathinfo = info.pathinfo(entity)
            }
        }

        if (!entity._id.$oid) {
            //if (LANGUAGES) entity._lang = state.defaultLanguage
            const { _id, ...data } = entity
            Query.insert({
                collection: info.collection,
                data,
            })
                .then(response => {
                    const _id = entity._id || response.data._id
                    if (!entity._id.$oid) entity._id = _id
                    if (info.afterSave) info.afterSave(entity)
                    if (response.data.redirect) entity.path = response.data.redirect
                    return entity
                })
                .then(entity => updateRefs(entity))
                .then(entity => updateDeps(entity))
                .then(entity => {
                    Query.refresh()
                    if (entity.type === "node" && !noredirect) {
                        if (!C.LANGUAGES) History.push(entity.path)
                        else if (entity.path.length > 0) History.push(entity.path[0].p)
                    }
                    resolve(entity)
                })
        } else {
            const { _id, ...$set } = entity

            Query.update({
                collection: info.collection,
                data: { $set },
                remove,
                _id,
            })
                .then(response => {
                    if (info.afterSave) info.afterSave(entity)
                    if (response.data.redirect) entity.path = response.data.redirect
                    return entity
                })
                .then(entity => (norefs ? entity : updateRefs(entity)))
                .then(entity => (norefs ? entity : updateDeps(entity)))
                .then(entity => {
                    Query.refresh()
                    if (
                        C.LANGUAGES === null &&
                        entity.type === "node" &&
                        oldPath === document.location.pathname &&
                        oldPath !== entity.path &&
                        !noredirect
                    ) {
                        History.push(entity.path)
                    }
                    resolve(entity)
                })
        }
    })
}

const create = (type, language) => {
    const typeInfo = getTypeInfo(type)
    if (!typeInfo) return null

    let entity = { _id: {}, type: typeInfo.type }
    if (typeInfo.type === "node") {
        entity.bundle = typeInfo.bundle
        entity._c = {}
        entity._c.pathauto = true
    }
    if (C.LANGUAGES) {
        entity._lang = C.LANGUAGES[0]
    }
    const info = getInfo(entity)
    //entity = wrapInfo(entity)

    info.fields.forEach(field => {
        if (!field.default) return

        entity = set(entity, field.name, field.default, language)
    })
    if (info.onNew) entity = info.onNew(entity)

    return entity
}

const load = (_id, collection = "node") => {
    const options = [
        {
            collection,
            query: { _id },
        },
    ]
    return axios
        .post("datamulti", { params: { options } })
        .then(response => {
            if (
                response.data &&
                response.data.length === 1 &&
                response.data[0].results &&
                response.data[0].results.length === 1
            )
                return response.data[0].results[0]
            return null
        })
        .catch(error => {
            console.log(error)
            return null
        })
}
export { save, create, load }
