/*import { LANGUAGES } from "../../../config"
import { entityFields } from "./entity"
import { nodeBundles, entityTypes } from "./entityAdmin"
import { fieldTypes } from "./field"
*/
import { isNumber } from "./util"
import { getFieldInfo } from "./info"

const _setSettingsRec = (parent, parentInfo, path, pathIndex, settings) => {
    //console.log(parent, parentInfo, path, pathIndex, settings)
    if (pathIndex === path.length - 1) {
        //if (!parent._e) parent._e = {}
        return {
            ...parent,
            _e: {
                ...parent._e,
                [path[pathIndex]]: {
                    ...(parent._e && parent._e[path[pathIndex]] ? parent._e[path[pathIndex]] : {}),
                    ...settings,
                },
            },
        }
    }
    const fieldInfo = getFieldInfo(parent, path[pathIndex], parentInfo)
    switch (fieldInfo.type) {
        case "list": {
            if (!isNumber(path[pathIndex + 1])) return null
            const itemIndex = parseInt(path[pathIndex + 1], 10)
            const arrayVal = parent[path[pathIndex]]
            if (pathIndex === path.length - 2) {
                return {
                    ...parent,
                    _e: {
                        ...parent._e,
                        [fieldInfo.name]: {
                            ...(parent._e && parent._e[fieldInfo.name]
                                ? parent._e[fieldInfo.name]
                                : {}),
                            _items: {
                                ...(parent._e &&
                                parent._e[fieldInfo.name] &&
                                parent._e[fieldInfo.name]._items
                                    ? parent._e[fieldInfo.name]._items
                                    : {}),
                                itemIndex: {
                                    ...(parent._e &&
                                    parent._e[fieldInfo.name] &&
                                    parent._e[fieldInfo.name]._items &&
                                    parent._e[fieldInfo.name]._items[itemIndex]
                                        ? parent._e[fieldInfo.name]._items[itemIndex]
                                        : {}),
                                    ...settings,
                                },
                            },
                        },
                    },
                }
            }
            return {
                ...parent,
                [path[pathIndex]]: [
                    ...arrayVal.slice(0, itemIndex),
                    _setSettingsRec(
                        arrayVal[itemIndex],
                        getFieldInfo(parent[path[pathIndex]], path[pathIndex + 1], fieldInfo),
                        path,
                        pathIndex + 2,

                        settings
                    ),
                    ...arrayVal.slice(itemIndex + 1),
                ],
            }
            //...arrayVal.slice(itemIndex + 1, arrayVal.length - itemIndex - 1),
        }
        case "obj":
            return {
                ...parent,
                [path[pathIndex]]: _setSettingsRec(
                    parent[path[pathIndex]],
                    fieldInfo,
                    path,
                    pathIndex + 1,
                    settings
                ),
            }
        default:
            return null
    }
}
export const setSettings = (entity, field, settings) => {
    const path = field.split(".")
    //const fInfo = entity.fieldInfo(path[0])
    return _setSettingsRec(entity, null, path, 0, settings)
}
