import { ReactComponent as Logo } from "./svg/logo.svg"
import { ReactComponent as LoadingLogo } from "./svg/alumetasistem-white-yellow.svg"

import { ReactComponent as ArrowLeft } from "./svg/carrousel-arrow-left-1.svg"
import { ReactComponent as ArrowRight } from "./svg/carrousel-arrow-right-1.svg"
import { ReactComponent as Menu } from "./svg/menu.svg"
import { ReactComponent as Close } from "./svg/close.svg"
import { ReactComponent as Settings } from "./svg/settings.svg"

const icons = {
    Logo,
    LoadingLogo,

    ArrowLeft,
    ArrowRight,
    Menu,
    Close,

    Settings,
}
export default icons
