import React from "react"
import { Field } from "lib"

const Section = ({ info, value, domRef, children, ...props }) => {
    //console.log(info, value)
    return (
        <div ref={domRef} id={`section-${info.fieldName}`} {...props}>
            <Field info={info} field="title" tag="h2" />
            <h3 className="section-shadow">
                <Field info={info} field="title" tag="span" noadmin />
            </h3>
            <div section-content="">{children}</div>
        </div>
    )
}
export default Section
