import { useState, useCallback, useEffect } from "react"
import History from "./history"

const pushSearch = params => {
    if (!params || Object.keys(params).length === 0) History.push(window.location.pathname)
    else {
        const search = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join("&")
        History.push(`${window.location.pathname}?${search}`)
    }
}
export default function useHistory() {
    const [location, setLocation] = useState(History.getLocation())

    const handleLocationChange = useCallback(
        (pathname, search) => setLocation({ pathname, search }),
        []
    )
    useEffect(() => {
        History.addLocationListener(handleLocationChange)

        return () => History.removeLocationListener(handleLocationChange)
    }, [handleLocationChange])
    return {
        location,
        push: History.push,
        pushSearch,
    }
}
