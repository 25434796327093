import LinkRenderer from "./LinkRenderer"
import Link from "./Link"
import Quote from "./Quote"
import Icon from "./Icon"

import Block from "./Block"
import Intro from "./Intro"
import Section from "./Section"

const fieldRenderer = {
    Intro,
    Block,
    Section,
    LinkRenderer,
    Link,
    Icon,
    Quote,
}
export default fieldRenderer
