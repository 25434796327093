import React, { Suspense, useContext, useMemo } from "react"
import { AuthContext } from "../auth"
//import Delayed from "./Delayed"
import Field from "./Field"
import Entity from "../entity"

const FieldManager = React.lazy(() =>
    import("admin").then(module => ({ default: module.FieldManager }))
)

const Region = props => {
    const { entity, language, region, style, nowrap, dynamic, children } = props
    //const [firstRender, setFirstRender] = useState(true)
    const { user } = useContext(AuthContext)

    const currentRegion = region || "content"
    const info = useMemo(() => ({ entity, user, region, language }), [
        entity,
        user,
        region,
        language,
    ])
    /*if (typeof window !== "undefined" && firstRender) {
        requestIdleCallback(
            () => {
                requestAnimationFrame(() => {
                    setFirstRender(false)
                })
            },
            { timeout: Math.floor(Math.random() * Math.floor(200)) }
        )
        return null
    }*/

    let o
    if (entity._o && entity._o[currentRegion]) {
        o = entity._o[currentRegion].split(",").filter(f => {
            const fs = Entity.getFields(entity).filter(f1 => f1.name === f)
            if (fs.length === 0) return false
            const r = fs[0].region
            return (
                (currentRegion === "content" && (r === undefined || r.trim() === "")) ||
                currentRegion === r
            )
        })
    } else {
        o = Entity.getFields(entity)
        if (currentRegion === "content")
            o = o.filter(f => f.region === undefined || f.region === "content")
        else o = o.filter(f => f.region === currentRegion)
        o = o.map(f => f.name)
    }
    if (nowrap)
        return (
            <>
                {o.map((f, i) => (
                    <Field key={i} field={f} info={info} />
                ))}
                {user && dynamic && (
                    <Suspense fallback={<div>...</div>}>
                        <FieldManager user={user} entity={entity} region={currentRegion} />
                    </Suspense>
                )}
            </>
        )
    const otherAttributes = { [`region-${currentRegion}`]: "" }

    return (
        //<Delayed maxDelay={200}>
        <div style={style} {...otherAttributes}>
            <div region-inner="">
                {o.map((f, i) => (
                    <Field key={i} field={f} info={info} />
                ))}
                <React.Fragment>{children}</React.Fragment>
            </div>
            {user && dynamic && (
                <Suspense fallback={null}>
                    <FieldManager user={user} entity={entity} region={currentRegion} />
                </Suspense>
            )}
        </div>
        //</Delayed>
    )
}
export default React.memo(Region)
