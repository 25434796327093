import axios from "axios"
import React from "react"
import { Entity } from "lib"
import { initialState, reducer } from "./reducer"
import RollList from "./RollList"
import RollMass from "./RollMass"

const formatFloat = (f, decimals, length = 0) => {
    const toks = f.toString().split(".")
    const i = toks[0].padStart(length, "0")
    const d = (toks.length < 2 ? "" : toks[1]).padEnd(decimals, "0")
    return d === "" ? i : i + "." + d
}
const formatFloatCompact = (f, decimals, length = 0) => {
    const toks = f.toString().split(".")
    const i = toks[0].padStart(length, "0")
    const d = (toks.length < 2 ? "" : toks[1]).padEnd(decimals, "0")
    return d === "" ? i : i + d
}
const formatInt = (n, length) => n.toString().padStart(length, "0")

const formatDate = d =>
    `${formatInt(d.getDate(), 2)}.${formatInt(d.getMonth() + 1, 2)}.${formatInt(
        d.getFullYear(),
        4
    )} ${formatInt(d.getHours(), 2)}:${formatInt(d.getMinutes(), 2)}`

const formatDateCompact = d =>
    `${formatInt(d.getDate(), 2)}${formatInt(d.getMonth() + 1, 2)}${formatInt(
        d.getFullYear(),
        4
    )}${formatInt(d.getHours(), 2)}${formatInt(d.getMinutes(), 2)}`

const RollView = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    React.useEffect(() => {
        if (state.stage !== "PRINT") return
        dispatch({ type: "printing" })
        const doPrint = async () => {
            const info = Entity.getInfo(state.roll)
            const d = new Date()
            const date = formatDate(d)
            const weight = formatFloat(state.weight, 2) + "Kg"
            const type = info.getLabel(state.roll)

            const code = `${formatFloatCompact(state.roll.width, 2, 2)}${formatFloatCompact(
                state.roll.gauge,
                2,
                2
            )}${formatDateCompact(d)}${formatFloatCompact(state.weight, 2, 3)}`
            const res = await axios.post("cmd", {
                cmd: "print",
                date,
                weight,
                type,
                code,
            })
            console.log(res)
            dispatch({ type: "print-end" })
        }
        doPrint()
    }, [state])
    //console.log(state)
    switch (state.stage) {
        case "MASS":
        case "PRINT":
        case "PRINTING":
            return <RollMass state={state} dispatch={dispatch} />
        default:
            return <RollList state={state} dispatch={dispatch} />
    }
}
export default RollView
