import { getInfo, resetInfo, getFields, getFieldInfo, getCollection } from "./info"
import { getType, entityTypes, getTypeInfo } from "./types"
import { fieldTypes } from "./fieldTypes"
import { addField } from "./addField"
import { get, set } from "./accessors"
import { create, load, save } from "./entity"
import { setSettings } from "./settings"

export default {
    set,
    get,
    getInfo,
    resetInfo,
    getFields,
    getFieldInfo,
    entityTypes,
    getType,
    getTypeInfo,
    getCollection,
    fieldTypes,
    addField,
    create,
    load,
    save,
    setSettings,
}
