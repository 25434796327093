const nodeBundles = {
    homepage: {
        name: "Pagină Acasă",
        info: "Pagină ",
        _noCreate: true,
    },
    page: {
        name: "Pagină",
        info: "Pagină generică",
        _noCreate: true,
    },
    NotFound: {
        name: "Pagină inexistentă",
        _noCreate: true,
    },
}

const entityTypes = {
    menu: {
        name: "Meniu",
        collection: "menu",
        getLabel: e => e.label,
        //_noCreate: true,
    },
}
export { nodeBundles, entityTypes }
