import React, { useState, useRef, useCallback, useMemo } from "react"
import renderers from "./renderers"
import { useSubscriptionProvider } from "../hooks"
import { useIntersection } from "../intersection"
import Delayed from "./Delayed"
import Entity from "../entity"
const { fieldTypes } = Entity
/*
const wrapCond = (cond, Wrapper, props, wrapped) => {
    if (!cond) return wrapped
    return <Wrapper {...props}>{wrapped}</Wrapper>
}*/

const Field = props => {
    const {
        domRef,
        info,
        field,
        tag,
        className,
        noadmin,
        rendererComp,
        renderer,
        _nowrap,
        _editParent,
        children,
        ...other
    } = props
    const localRef = useRef()
    const localDomRef = domRef || localRef
    const mounted = useRef(true)
    const visible = useRef()
    const parents = useRef()
    const [localState, localSetState] = useState()
    const localInfo = useMemo(() => {
        if (!info) {
            console.log(`Field ${field} has no INFO.`)
            return {}
        }
        //console.log(info)
        const { user, entity, language } = info
        const parent = info.value
        const parentInfo = info.fieldInfo
        const fieldName = info.fieldName ? `${info.fieldName}.${field}` : field
        const fieldInfo = parent
            ? Entity.getFieldInfo(parent, field, parentInfo)
            : Entity.getFieldInfo(entity, fieldName)
        const rawValue = parent
            ? Entity.get(parent, field, language)
            : Entity.get(entity, fieldName, language)
        const value =
            fieldInfo && fieldInfo.prepareView ? fieldInfo.prepareView(rawValue) : rawValue
        const states = fieldInfo ? fieldInfo.states || info.states : info.states
        const state = fieldInfo && fieldInfo.states ? localState : info.state
        const setState = fieldInfo && fieldInfo.states ? localSetState : info.setState
        if (user && !noadmin && entity && entity._id.$oid) {
            if (_editParent) {
                parents.current = [...(info.parents ? info.parents.current : [])]
                if (parents.current.length > 0)
                    parents.current[parents.current.length - 1] = Object.assign(
                        {},
                        parents.current[parents.current.length - 1],
                        { domRef: localDomRef }
                    )
            } else
                parents.current = [
                    ...(info.parents ? info.parents.current : []),
                    {
                        domRef: localDomRef,
                        entity,
                        fieldInfo,
                        field: fieldName,
                        states,
                        state,
                        setState,
                    },
                ]
        }
        //console.log(field, parent, parentFieldInfo, fieldInfo, conf, value)
        return {
            domRef: localDomRef,
            user,
            entity,
            language,
            fieldName,
            field,
            fieldInfo,
            value,
            parents,
            states,
            state,
            setState,
        }
    }, [info, localState, localDomRef, noadmin, _editParent, field])
    //console.log("RENDER FIELD", field, localInfo)
    const { user, entity, fieldInfo, value } = localInfo
    const setFieldAdmin = useRef(useSubscriptionProvider("fieldAdmin"))

    const mouseOver = useRef(false)
    const onMouseOver = useCallback(() => {
        //console.log("mouse over", parents.current)
        if (mouseOver.current) return

        mouseOver.current = true
        //console.log(parents.current)
        setFieldAdmin.current({ mouse: "enter", fieldLine: parents.current })
    }, [])

    const onMouseLeave = useCallback(() => {
        //console.log("mouse leave")
        window.requestAnimationFrame(() => {
            mouseOver.current = false
            setFieldAdmin.current({ mouse: "leave", fieldLine: parents.current })
        })
    }, [])
    //console.log(field, conf, confLocal)
    //console.log(language, entity, field, fieldInfoLocal, val)
    const Renderer =
        props.renderer ||
        (fieldInfo
            ? fieldInfo.renderer
                ? renderers[fieldInfo.renderer]
                : renderers[fieldInfo.typeName] ||
                  renderers[fieldInfo.name] ||
                  (fieldTypes[fieldInfo.typeName]
                      ? fieldTypes[fieldInfo.typeName].renderer
                          ? renderers[fieldTypes[fieldInfo.typeName].renderer]
                          : null
                      : null) ||
                  null
            : null)

    const onIntersect = useCallback(
        entry => {
            if (!mounted.current) return
            if (fieldInfo.onIntersect) fieldInfo.onIntersect(entry)

            if (!fieldInfo._visibility || visible.current || !entry.isIntersecting) return
            visible.current = true
            localDomRef.current.classList.add(
                fieldInfo && fieldInfo.classVisible ? fieldInfo.classVisible : "visible"
            )
            //setVisible(true)
        },
        [visible, fieldInfo, localDomRef]
    )
    //console.log(fieldInfo, Renderer)
    useIntersection(
        fieldInfo && (fieldInfo._visibility || fieldInfo.onIntersect) && Renderer
            ? localDomRef
            : null,
        onIntersect
    )

    //console.log(field, fieldInfo, value, Renderer)
    if (fieldInfo && fieldInfo._hidden && !user) return null
    if (!fieldInfo || fieldInfo.display === false) return null
    if (fieldInfo.isEmpty && fieldInfo.isEmpty(value)) return null
    if (!Renderer) {
        console.log("Renderer not found for ", field, fieldInfo, value, entity)
        return null
    }

    //if (info.states) console.log(info.state, conf)
    //if (info.states) console.log(info.state, info.states, fieldInfo)
    if (
        info.states &&
        fieldInfo.state &&
        fieldInfo.state.indexOf(info.state || info.states[0].val) < 0
    )
        return null

    //const entityConf = entity ? entity._conf || {} : {}
    //const fieldConf = conf || entity ? entity.getConf(field) || {} : {}
    //console.log(field, fieldConf)

    const childFields =
        value && value._e
            ? value._o
                ? value._o
                      .split(",")
                      .filter(f => Object.keys(value._e).indexOf(f) >= 0 && !!value._e[f].type)
                : Object.keys(value._e).filter(f => !!value._e[f].type)
            : []
    //console.log(field, fieldInfo, value, childFields)
    //? Object.keys(entityConf).filter(f => entityConf[f] && entityConf[f]._block === field)
    //: []

    const wrapperProps = {}
    //console.log(field, fieldInfoLocal)
    if (fieldInfo.id) wrapperProps.id = fieldInfo.id
    /*
    let classes =
        `field ${field.split(".").pop()} field-${fieldInfo.typeName || fieldInfo.type} ` +
        (fieldInfo._class ? fieldInfo._class : "") +
        (className || "")
    */
    //let classes = className || ''
    wrapperProps[`field-${field.split(".").pop()}`] = ""
    wrapperProps[`type-${(fieldInfo.typeName || fieldInfo.type).toLowerCase()}`] = ""
    //if (visible) classes += " field-visible"

    let classes = []
    if (className) classes.push(className)
    if (fieldInfo._class) classes.push(fieldInfo._class)
    if (visible.current) classes.push(fieldInfo.classVisible || "visible")
    if (classes.length > 0) wrapperProps.className = classes.join(" ")
    //if (data && user) {
    //console.log("add  mouse", user && !noadmin && entity)
    if (user && !noadmin && entity && entity._id.$oid) {
        //console.log("add  mouse")
        //if (!parents.current)
        //setupLineage()
        wrapperProps.onMouseOver = onMouseOver
        //wrapperProps.onMouseEnter = onMouseOver
        wrapperProps.onMouseLeave = onMouseLeave
    }

    const renderField = props =>
        children || childFields.length > 0 ? (
            <Renderer {...props} info={localInfo} value={value}>
                {children}
                {childFields &&
                    childFields.map((f, i) => <Field key={i} info={localInfo} field={f} />)}
            </Renderer>
        ) : (
            <Renderer {...props} info={localInfo} value={value} />
        )

    if (fieldInfo.delayed) {
        if (_nowrap || fieldInfo._nowrap) {
            return (
                <Delayed maxDelay={fieldInfo.maxDelay}>
                    {renderField({
                        ...wrapperProps,
                        ...other,
                        domRef: localDomRef,
                    })}
                </Delayed>
            )
        }
        const Tag = tag || fieldInfo.tag || "div"
        if (fieldInfo._inner) {
            return (
                <Delayed maxDelay={fieldInfo.maxDelay}>
                    <Tag ref={localDomRef} has-inner="" {...wrapperProps}>
                        <div inner="">
                            {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                            {renderField(other)}
                        </div>
                    </Tag>
                </Delayed>
            )
        }
        return (
            <Delayed maxDelay={fieldInfo.maxDelay}>
                <Tag ref={localDomRef} {...wrapperProps}>
                    {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                    {renderField(other)}
                </Tag>
            </Delayed>
        )
    }

    //console.log("field", localInfo.fieldName, value)
    //console.log(fieldInfo, _nowrap)
    if (_nowrap || fieldInfo._nowrap) {
        return renderField({
            ...wrapperProps,
            ...other,
            domRef: localDomRef,
        })
    }
    const Tag = tag || fieldInfo.tag || "div"
    if (fieldInfo._inner) {
        return (
            <Tag ref={localDomRef} has-inner="" {...wrapperProps}>
                <div inner="">
                    {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
                    {renderField(other)}
                </div>
            </Tag>
        )
    }
    return (
        <Tag ref={localDomRef} {...wrapperProps}>
            {fieldInfo && fieldInfo.pre && fieldInfo.pre()}
            {renderField(other)}
        </Tag>
    )
}

export default React.memo(Field)
