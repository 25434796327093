import React from "react"
import { realPath } from "../../util"

const VideoFile = ({ domRef, info, value, ...props }) => {
    if (!value) return null
    const { videoProps, ...rest } = props
    const other = {}
    if (value.poster && value.poster.length > 0) other.poster = value.poster[0].url
    return (
        <div ref={domRef} {...rest}>
            <video controls playsInline {...other} {...videoProps}>
                {value.file.map((v, i) => (
                    <source key={i} src={realPath(v.url)} type="video/mp4" />
                ))}
            </video>
        </div>
    )
}

export default VideoFile
