import axios from "axios"
//import C from "../conf"
//import { queryRefreshAll } from "lib/hooks/useQuery"
import { refresh, fetch, execute } from "./useQuery"

const updateMany = (update, cb = null) => {
    let collection = update.collection || "node"
    return axios
        .put(`datamany/${collection}`, update)
        .then(response => {
            if (cb) cb(response)
        })
        .catch(error => console.log(error))
}

const update = (update, cb = null) => {
    let id
    if (update.id) id = update.id
    else {
        if (update._id) id = update._id["$oid"]
        else return
    }
    let collection = update.collection || "node"
    return axios
        .put(`data/${collection}/${id}`, update)
        .then(response => {
            if (cb) cb(response)
            return response
        })
        .catch(error => console.log(error))
}
const insert = (update, cb = null) => {
    let collection = update.collection || "node"
    return axios
        .post(`data/${collection}`, update)
        .then(response => {
            if (cb) cb(response)
            return response
        })
        .catch(error => console.log(error))
}

const findFilesRecursive = (entity, files) => {
    if (!entity) return
    if (typeof entity !== "object") return

    if (entity.url) {
        files.push(entity.url)
        return
    }
    if (Array.isArray(entity)) {
        entity.forEach(item => findFilesRecursive(item, files))
        return
    }
    Object.keys(entity).forEach(key => findFilesRecursive(entity[key], files))
}
const findFiles = entity => {
    let files = []
    findFilesRecursive(entity, files)
    return files
}

const query = (query, cb) => {
    return axios
        .post("datamulti", { params: { options: [query] } })
        .then(response => {
            if (cb) {
                if (response.data && response.data[0]) cb(response.data[0])
                else cb(null)
            }
            return response.data[0]
        })
        .catch(e => console.log(e))
}

export default {
    query,
    update,
    insert,
    updateMany,
    findFiles,
    refresh,
    fetch,
    execute,
}
