import axios from "axios"
import { useCallback, useMemo, useState, useRef, useEffect } from "react"
//import C from "../conf"
import { useUnmounted } from "../hooks"
//import { query as doQuery, registerQuery, unregisterQuery } from "../api"
import { requestIdleCallback } from "../util"

const DELTA = 300000
export const isHash = val => /^h-?\d+$/.test(val)
let storage = {
    queries: {},
    promises: {},
    t: {},
    willFetch: false,
    active: [],
    //qid: 1,
    hydrated: false,
}
const hashstr = s => {
    let hash = 0
    if (s.length === 0) return hash
    for (let i = 0; i < s.length; i++) {
        let char = s.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash = hash & hash // Convert to 32bit integer
    }
    return "h" + hash
}
const isSSR = typeof window === "undefined"

const schedule = cb => {
    if (typeof window === "undefined") return cb()

    const scheduleFrame = frameStart => {
        const delta = performance.now() - frameStart
        if (delta > 6) {
            requestAnimationFrame(scheduleFrame)
            return
        }
        cb()
    }
    requestIdleCallback(
        () => {
            requestAnimationFrame(() => {
                scheduleFrame()
            })
        },
        { timeout: Math.floor(Math.random() * Math.floor(200)) }
    )
}

const fetch = () => {
    if (!storage.willFetch) return
    //console.log("FETCH", Object.keys(storage.queries).length, Object.keys(storage.queries))
    const queries = storage.queries
    const promises = storage.promises
    storage.queries = {}
    storage.promises = {}
    storage.willFetch = false

    const keys = Object.keys(queries)
    const options = keys.map(key => {
        const { noCache, ...q } = queries[key]
        return q
    })
    //progressStart()
    axios
        .post("datamulti", { params: { options } })
        .then(response => {
            //progressDone()
            //console.log("FETCHED", Object.keys(queries).length, Object.keys(queries))
            if (response.status !== 200 || typeof response.data === "string") {
                keys.forEach(key => {
                    for (let p of promises[key]) {
                        p(null)
                    }
                })
                return
            }
            //console.log(response, promises)
            keys.forEach((key, i) => {
                const data = response.data[i]
                storage.t[key] = Date.now()
                if (!isSSR) {
                    if (!queries[key].noCache) {
                        try {
                            sessionStorage.setItem(key, JSON.stringify(data))
                        } catch (error) {
                            sessionStorage.clear()
                        }
                    }
                } else global.store[key] = JSON.stringify(data)

                for (let p of promises[key]) {
                    schedule(() => {
                        p(data)
                    })
                }
            })
        })
        .catch(error => {
            console.log(error)
        })
}

const refresh = () => {
    //console.log("REFRESH", storage.active)
    //sessionStorage.clear()
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i)
        if (isHash(key)) sessionStorage.removeItem(key)
    }
    if (!storage.willFetch) {
        storage.willFetch = true
        if (!isSSR) requestIdleCallback(fetch)
    }
    storage.active.forEach(item => {
        storage.queries[item.key] = item.q
        if (!storage.promises[item.key]) storage.promises[item.key] = []
        storage.promises[item.key].push(item.cb)
    })
}

const execute = q => {
    const key = hashstr(JSON.stringify(q))
    //console.log(key, q)
    try {
        if (!isSSR) {
            if (window.isHydrating) {
                if (window.initialStore[key]) {
                    storage.t[key] = Date.now()
                    sessionStorage.setItem(key, window.initialStore[key])
                    return JSON.parse(window.initialStore[key])
                }
            }
            if (!q.noCache) {
                const item = sessionStorage.getItem(key)
                if (item) {
                    if (storage.t[key] && Date.now() - storage.t[key] < DELTA) {
                        try {
                            return JSON.parse(item)
                        } catch {}
                    }
                    delete storage.t[key]
                }
            }
        } else if (global.store[key]) return JSON.parse(global.store[key])
    } catch (e) {
        console.log(e)
    }
    storage.queries[key] = q
    if (!storage.willFetch) {
        storage.willFetch = true
        if (!isSSR) requestIdleCallback(fetch)
        /*setTimeout(() => {
            doFetch()
        })*/
    }
    const promise = new Promise(resolve => {
        const cb = data => resolve(data)
        if (!storage.promises[key]) storage.promises[key] = []
        storage.promises[key].push(cb)
    })
    if (isSSR) global.storePromises.push(promise)

    return promise
}
const registerQuery = (q, cb) => {
    const key = hashstr(JSON.stringify(q))
    //storage.qid += 1
    //storage.active.push({ qid: storage.qid, key, q, cb })
    storage.active.push({ key, q, cb })
    //console.log("register", key, q)
    return key
    //storage.qid
}
const unregisterQuery = key => {
    //console.log("unregister", key, storage.active)
    storage.active = storage.active.filter(item => item.key !== key)
    //console.log("AFTER unregister -> ", storage.active)
}
//const isRegistered = key => storage.active.filter(item => item.key === key).length > 0

const defaultConfig = {
    single: false,
    pageSize: false,
    count: false,
    //setEntityInfo: false,
}
const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)

const prepareQuery = (query, config) => {
    const { pageSize, count } = config

    //let q = { ...query }
    if (pageSize) {
        const page = config.page || 0
        const skip = page * pageSize
        const limit = pageSize
        return Object.assign({}, query, {
            skip,
            limit,
            count: true,
        })
    }
    if (count) {
        return Object.assign({}, query, {
            count: true,
        })
    }
    return query
}

const resultToData = (res, config) => {
    const results = res.results
    if (config.single) {
        if (results.length < 1) return null
        //return config.setEntityInfo ? wrapInfo(results[0]) : results[0]
        return results[0]
    }
    //return config.setEntityInfo ? results.map(wrapInfo) : results
    return results
}

const useQuery = (query, config = defaultConfig) => {
    const unmounted = useUnmounted()
    const runningQuery = useRef({})
    const queryId = useRef()
    const state = useRef({})
    const [, triggerRender] = useState(false)
    //if (config.tag === "node") console.log("useQuery1", state.current)

    const onResults = useCallback(
        data => {
            if (unmounted.current) return
            const currentQuery = { query, config }
            if (!deepEqual(currentQuery, runningQuery.current)) return

            state.current = {
                status: "loaded",
                data: resultToData(data, config),
                total: data.total,
            }
            //console.log("RESULTS:", state.current)
            triggerRender(status => !status)
        },
        [query, config, unmounted]
    )

    const waitData = useCallback(
        data => {
            data.then(onResults).catch(error => {
                console.log(error)
                if (unmounted.current) return
                state.current = { status: "error", data: null, total: 0 }
                triggerRender(status => !status)
            })
        },
        [onResults, unmounted]
    )

    useMemo(() => {
        const currentQuery = { query, config }

        if (deepEqual(currentQuery, runningQuery.current)) return
        runningQuery.current = currentQuery

        if (queryId.current) {
            //console.log("goto unregister 1", queryId.current, query)
            unregisterQuery(queryId.current)
        } //else console.log("nogoto unregister 1")
        queryId.current = null
        if (!query) {
            state.current = {}
            return
        }
        const q = prepareQuery(query, config)
        queryId.current = registerQuery(q, onResults)
        //console.log("QUERY", queryId.current, q)
        const data = execute(q)

        if (data instanceof Promise) {
            state.current.status = "loading"
            waitData(data, config)
        } else {
            state.current = {
                status: "loaded",
                data: resultToData(data, config),
                total: data.total,
            }
        }
    }, [query, config, onResults, waitData])

    //useEffect(fetch)
    useEffect(
        () => () => {
            //console.log("goto unregister 2", queryId.current, query)
            if (queryId.current) unregisterQuery(queryId.current)
            queryId.current = null
        },
        []
    )

    //if (config.tag === "node")
    //console.log("useQuery", config.tag || query, queryId.current, state.current)
    //console.log(state.current)
    return [state.current.data, state.current.status, state.current.total]
}
export { refresh, fetch, execute }
export default useQuery
