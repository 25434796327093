import React from "react"
import { renderVideo } from "../../util"

const Video = ({ domRef, info, value, ...props }) => {
    if (!value) return null

    return (
        <div ref={domRef} {...props}>
            {renderVideo(value, {
                "data-show-text": false,
                "data-show-captions": false,
            })}
        </div>
    )
}

export default Video
