import React from "react"
import { Link } from "lib"

const LinkRenderer = ({ domRef, info, value, ...props }) => {
    if (!value) return null

    return (
        <Link domRef={domRef} {...props} to={value.path || ""}>
            {value.label || ""}
        </Link>
    )
}
export default React.memo(LinkRenderer)
