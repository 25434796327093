import React, { useMemo, useRef } from "react"
//import NProgress from "nprogress"
import { C, useAuthContext, useQuery } from "lib"
import useCustomNode from "./useCustomNode"
import Layout from "../Layout"
import Admin from "../Admin"
/*const nodeNotFound = wrapInfo({
    _id: { $oid: -1 },
    bundle: "NotFound",
    type: "node",
    title: "Pagină inexistentă",
    body: "Pagina nu a fost găsită.",
})*/
const nodeNotFound = {
    _id: { $oid: -1 },
    bundle: "NotFound",
    type: "node",
    title: "Pagină inexistentă",
    body: "Pagina nu a fost găsită.",
}

const pathField = C.LANGUAGES ? "path.p" : "path"
const buildNodeQuery = pathname => ({
    query: {
        $or: [{ [pathField]: pathname }, { alias: pathname }],
    },
})
const queryConfig = { single: true, setEntityInfo: true, tag: "node" }

const useLocation = (pathname, search, language, status) => {
    const location = useRef({ pathname, search, language, first: true })
    const prevStatus = useRef(status)

    if (location.current.pathname === pathname) location.current.search = search
    else {
        if (status !== "loading") {
            location.current = { pathname, search, language, first: false }
        }
    }
    prevStatus.current = status
    return location.current
}

const useNodeRetriever = pathname => {
    const state = useRef({})
    //const custom = useRef()
    const customNode = useCustomNode(pathname)
    useMemo(() => {
        if (
            C.LANGUAGES &&
            state.current.node &&
            state.current.node.path.filter(path => path.p === pathname).length > 0
        )
            return

        //custom.current = useCustomNode(pathname)
        if (customNode) {
            state.current.query = null
        } else {
            state.current.query = buildNodeQuery(pathname)
        }
    }, [pathname, customNode])

    const [resultNode, status] = useQuery(state.current.query, queryConfig)
    //const prevNode = state.current.node
    //const currentNode =
    if (customNode || status === "loaded") {
        state.current.node = customNode || resultNode
        return [state.current.node, "loaded"]
    }
    return [state.current.node, status]
    /*if (customNode) {
        state.current.node = customNode
        return [customNode, "loaded"]
    }
    state.current.node = resultNode || customNode
    return [state.current.node, status]*/
}
const NodeManager = ({ pathname, search, language }) => {
    const { user, logout } = useAuthContext()
    const [resultNode, status] = useNodeRetriever(pathname)
    const location = useLocation(pathname, search, language, status)
    const node = status === "loaded" ? resultNode || nodeNotFound : resultNode
    /*
    console.log(
        "render",
        location.pathname,
        location.search,
        status,
        node ? node.path : null,
        resultNode
    )*/
    //console.log(location)
    if (user && user.isAdmin && location.search["mode"] && location.search["mode"] === "design") {
        return <Admin user={user} logout={logout} node={node} pathname={location.pathname} design />
    }

    return (
        <>
            <Layout
                location={location.pathname}
                search={location.search}
                language={location.language}
                node={node}
                first={location.first}
            />
            <Admin user={user} logout={logout} node={node} />
        </>
    )
}

export { buildNodeQuery }
export default React.memo(NodeManager)
