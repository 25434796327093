import React, { useEffect } from "react";
import { realPath } from "../../util";
//import Carousel from "../Carousel"
import LazyImage from "../LazyImage";

const Img = ({
  domRef,
  info,
  className,
  value,
  wrap,
  carousel,
  carouselProps,
  ...props
}) => {
  const { fieldInfo } = info;

  useEffect(() => {
    if (value && value.length === 0 && fieldInfo.onActive)
      fieldInfo.onActive(value[0], this.props.data);
  }, [fieldInfo, value]);
  /*
    const renderCarousel = () => {
        //const { value, fieldInfo, data } = this.props

        const extraProps = {
            autoSize: true,
            images: value,
            ...carouselProps,
        }

        if (fieldInfo.arrowRenderers) extraProps.arrowRenderers = fieldInfo.arrowRenderers
        if (fieldInfo.renderNavigator) extraProps.renderNavigator = fieldInfo.renderNavigator
        if (fieldInfo.onActive) extraProps.onActive = this.onActive

        const itemProps = {}
        if (extraProps.fillParent) itemProps.bg = true
        return (
            <Carousel {...fieldInfo.widgetProps} {...extraProps}>
                {value.map((img, i) => {
                    if (fieldInfo.itemRenderer) return fieldInfo.itemRenderer(img, i)
                    const mime = img.mime ? img.mime.split("/") : "image"
                    if (mime[0] === "video")
                        return (
                            <video autoPlay playsInline muted loop>
                                <source src={realPath(img.url)} type={img.mime} />
                            </video>
                        )
                    return <LazyImage key={i} src={img} {...itemProps} />
                })}
            </Carousel>
        )
    }*/

  const imgProps = {};
  if (fieldInfo.imageStyle) imgProps.imageStyle = fieldInfo.imageStyle;
  if (fieldInfo.bg) imgProps.bg = fieldInfo.bg;
  if (fieldInfo.proportional) imgProps.proportional = fieldInfo.proportional;

  if (!value || value.length === 0) return null;

  /*if (carousel || fieldInfo.widget === "carousel" || fieldInfo._carousel) {
        return renderCarousel()
    }*/

  /*if (value.length===1) {
			if (fieldInfo.itemRenderer) return <div {...props}>{fieldInfo.itemRenderer(value[0], 0, data.language)}</div>
			const mime = value[0].mime?value[0].mime.split('/'):'image'
			if (mime[0]==='video') {
				return <video ref={domRef} className={className} autoPlay playsInline muted loop {...props}>
	                        <source src={realPath(value[0].url)} type={value[0].mime}/>
	                    </video>
			}
			if (mime[0]==='image') {
				if (fieldInfo.wrap) return <div className={className} ref={domRef}><LazyImage src={value[0]} {...props} {...imgProps} {...fieldInfo.widgetProps}/></div>
				return <LazyImage  ref={domRef} className={className} src={value[0]} {...props} {...imgProps} {...fieldInfo.widgetProps}/>
			}
			return null
		}*/

  return (
    <>
      {value.map((img, i) => {
        if (fieldInfo.itemRenderer) return fieldInfo.itemRenderer(img, i);
        const mime = img.mime ? img.mime.split("/") : "image";
        if (mime[0] === "video")
          return (
            <video
              ref={domRef}
              className={className}
              autoPlay
              playsInline
              muted
              loop
            >
              <source src={realPath(img.url)} type={img.mime} />
            </video>
          );

        if (wrap || fieldInfo.wrap)
          return (
            <div key={i} ref={domRef} className={`${className || ""} img`}>
              <LazyImage src={img} {...imgProps} {...props} />
            </div>
          );
        return (
          <LazyImage
            domRef={domRef}
            key={i}
            src={img}
            className={className}
            {...imgProps}
            {...props}
          />
        );
      })}
    </>
  );
};

export default Img;
