const entityFields = {
    NotFound: [
        {
            name: "title",
            type: "text",
            label: "Titlu",
            tag: "h1",
            region: "header",
        },
        { name: "body", type: "html", label: "Conținut" },
    ],
    homepage: [],
    page: [],
    menu: [
        { name: "label", type: "text", label: "Nume" },
        { name: "items", type: "list", items: "menuItem", label: "Meniuri" },
    ],
}
export { entityFields }
