import auth from "../../conf/authorize"

let permissions = {}
Object.keys(auth).forEach(perm => {
    if (perm === "entity") return
    permissions[perm] = { roles: auth[perm] }
})

class User {
    constructor(profile) {
        this.profile = profile
        if (profile) {
            this.name = profile["https://linuxship.ro/authorization/realName"] || profile.name
            this.roles = profile["https://linuxship.ro/authorization/roles"]
            this.isAdmin = this.roles.indexOf("admin") >= 0
            this.isEditor = this.roles.indexOf("admin") >= 0 || this.roles.indexOf("editor") >= 0
        }
    }

    can = (perm, data = null) => {
        //console.log(perm, data)
        if ((perm === "create" && data === "NotFound") || (data && data.bundle === "NotFound"))
            return false
        //if (this.isAdmin || this.isEditor) return true
        if (this.isAdmin) return true

        switch (perm) {
            case "create":
                return (
                    auth.create.permit.byType[data] &&
                    this.roles.filter(role => auth.create.permit.byType[data].indexOf(role) >= 0)
                        .length > 0
                )

            case "update": {
                const type =
                    typeof data === "string" ? data : data.type === "node" ? data.bundle : data.type
                return (
                    auth.update.permit.byType[type] &&
                    this.roles.filter(role => auth.update.permit.byType[type].indexOf(role) >= 0)
                        .length > 0
                )
            }
            case "delete": {
                const type =
                    typeof data === "string" ? data : data.type === "node" ? data.bundle : data.type
                return (
                    auth.delete.permit.byType[type] &&
                    this.roles.filter(role => auth.delete.permit.byType[type].indexOf(role) >= 0)
                        .length > 0
                )
            }
            case "updateField": {
                const { field, entity } = data
                const type =
                    typeof entity === "string"
                        ? entity
                        : entity.type === "node"
                        ? entity.bundle
                        : entity.type
                return (
                    auth.update.permit.byType[type] &&
                    this.roles.filter(role => auth.update.permit.byType[type].indexOf(role) >= 0)
                        .length > 0 &&
                    (!auth.updateField.restrict.byName[field] ||
                        this.roles.filter(
                            role => auth.updateField.restrict.byName[field].indexOf(role) >= 0
                        ).length > 0)
                )
            }
            case "addField": {
                const { fieldType, entity } = data
                const type =
                    typeof entity === "string"
                        ? entity
                        : entity.type === "node"
                        ? entity.bundle
                        : entity.type
                return (
                    auth.update.permit.byType[type] &&
                    this.roles.filter(role => auth.update.permit.byType[type].indexOf(role) >= 0)
                        .length > 0 &&
                    auth.addField.permit.byType[fieldType] &&
                    this.roles.filter(
                        role => auth.addField.permit.byType[fieldType].indexOf(role) >= 0
                    ).length > 0
                )
            }
            default:
                return false
        }
        //console.log(permissions)
        /*
        if (!permissions[perm]) return false
        const pdata = permissions[perm]
        if (pdata.cb) return pdata.cb(this, perm, data)

        if (this.profile && pdata.roles)
            return this.profile.roles.reduce(
                (acc, role) => pdata.roles.includes(role) || acc,
                false
            )
            */
        //return false
    }

    hasOneOf = roles => {
        for (let i = 0; i < roles.length; i++) {
            if (this.roles.indexOf(roles[i]) >= 0) return true
        }
        return false
    }

    is = role => {
        if (role === "editor") return this.isEditor
        return this.roles.indexOf(role) >= 0
    }
}
export default User
