import React, { useRef, useEffect, useCallback } from "react"
import { AppHeader, Footer } from "components"
import Node from "./node/Node"
//import TooltipManager from "./layout/TooltipManager"
//const Admin = React.lazy(() => import("./layout/Admin"))

const useIsScrolledToTop = () => {
    const notTop = useRef(false)
    const scroll = useRef(0)
    const direction = useRef(false)
    const delta = useRef(0)

    const running = useRef()
    const update = useCallback(() => {
        let root
        if (scroll.current > 100) {
            if (!notTop.current) {
                notTop.current = true
                if (!root) root = document.getElementById("root")
                root.classList.add("not-top")
            }
        } else {
            if (notTop.current) {
                notTop.current = false
                if (!root) root = document.getElementById("root")
                root.classList.remove("not-top")
            }
        }
        if (
            (delta.current >= 0 && direction.current) ||
            (delta.current < 0 && !direction.current)
        ) {
            running.current = false
            return
        }
        if (delta.current >= 0) {
            direction.current = true
            if (!root) root = document.getElementById("root")
            root.classList.remove("up")
            root.classList.add("down")
        } else {
            direction.current = false
            if (!root) root = document.getElementById("root")
            root.classList.remove("down")
            root.classList.add("up")
        }
        running.current = false
    }, [scroll, notTop, delta, running, direction])
    const handleScroll = useCallback(() => {
        const oldScroll = scroll.current
        scroll.current = window.scrollY
        delta.current = scroll.current - oldScroll

        if (!running.current) {
            running.current = true
            window.requestAnimationFrame(update)
            //setTimeout(update, 500)
        }
    }, [update, scroll, delta, running])

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true })
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [handleScroll])
}
const useIsHomepage = node => {
    const nodeRef = useRef()
    useEffect(() => {
        if (!node || nodeRef.current === node.bundle) return
        nodeRef.current = node.bundle

        const root = document.getElementById("root")
        if (node.bundle === "homepage") {
            root.classList.add("homepage")
        } else {
            root.classList.remove("homepage")
        }
    }, [node])
}
const Layout = ({ location, search, language, node, first }) => {
    useIsScrolledToTop()
    useIsHomepage(node)
    //console.log("render layout", location, search, node)
    return (
        <>
            <AppHeader node={node} first={first} language={language} />
            <Node
                location={location}
                search={search}
                language={language}
                node={node}
                first={first}
            />

            <Footer node={node} language={language} />
            <div id="modal-container" />
        </>
    )
}
export default React.memo(Layout)
