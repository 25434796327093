import { useMemo } from "react";
import customNodes from "conf/node";

export const isCustomNode = pathname => {
  if (customNodes[pathname])
    //return wrapInfo({ ...customNodes[pathname], type: "node", path: pathname })
    return { ...customNodes[pathname], type: "node", path: pathname };
  return Object.keys(customNodes).reduce((acc, key) => {
    if (!!acc || !customNodes[key].pattern) return acc;
    if (customNodes[key].pattern.test(pathname))
      return { ...customNodes[key], type: "node", path: pathname };
    return acc;
  }, false);
};
const useCustomNode = pathname =>
  useMemo(() => isCustomNode(pathname), [pathname]);
export default useCustomNode;
