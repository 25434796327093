import React from "react"
import BackspaceIcon from "@material-ui/icons/Backspace"
import CancelIcon from "@material-ui/icons/ChevronLeft"
import CheckIcon from "@material-ui/icons/Check"

const digits = [7, 8, 9, 4, 5, 6, 1, 2, 3]
const RollMass = ({ state, dispatch }) => {
    const handleAddDigit = e => {
        dispatch({ type: "add-digit", digit: e.currentTarget.dataset.value })
    }
    const handlePrint = e => {
        dispatch({ type: "print" })
    }
    const handleCancel = e => {
        dispatch({ type: "cancel" })
    }
    const handleBackspace = e => {
        dispatch({ type: "backspace" })
    }
    return (
        <div className="mass-calc">
            <div className="mass-keyboard">
                <div onClick={handleCancel} className="cancel">
                    <CancelIcon />
                </div>
                <div className="mass">{state.weight}</div>
                <div onClick={handleBackspace} className="backspace">
                    <BackspaceIcon />
                </div>
                {digits.map((d, i) => (
                    <div key={i} onClick={handleAddDigit} data-value={d}>
                        {d}
                    </div>
                ))}
                <div onClick={handleAddDigit} data-value={0}>
                    0
                </div>
                <div onClick={handleAddDigit} data-value={"."}>
                    .
                </div>
                <div onClick={handlePrint} className="ok">
                    <CheckIcon />
                </div>
            </div>
        </div>
    )
}
export default RollMass
