import {
    nodeBundles as customNodeBundles,
    entityTypes as customEntityTypes,
} from "conf/entityAdmin"
import { entityFields } from "./entityFields"

const entitySettings = [
    {
        name: "_nodel",
        type: "bool",
        label: "Nu se poate șterge",
        widget: "toggle",
        require: "admin",
    },
    { name: "_cssid", type: "text", label: "CSS ID" },
    { name: "_class", type: "text", label: "Clasa CSS" },
]
const implicitNodeFields = [{ name: "_seo", type: "seo", label: "SEO", region: "SEO" }]
//const nodeBundles = {} //Object.assign({}, customNodeBundles)
const entityTypes = {} //Object.assign({}, customEntityTypes)
Object.keys(customNodeBundles).forEach(bundle => {
    entityTypes[bundle] = {
        ...customNodeBundles[bundle],
        type: "node",
        bundle,
        collection: "node",
        settings: [...entitySettings, ...(customNodeBundles[bundle].settings || [])],
        fields: [...implicitNodeFields, ...entityFields[bundle]].map(field =>
            customNodeBundles[bundle].fields && customNodeBundles[bundle].fields[field.name]
                ? { ...field, ...customNodeBundles[bundle].fields[field.name] }
                : field
        ),
    }
})
Object.keys(customEntityTypes).forEach(type => {
    entityTypes[type] = {
        ...customEntityTypes[type],
        type,
        collection: customEntityTypes[type].collection || type,
        settings: [...entitySettings, ...(customEntityTypes[type].settings || [])],
        fields: entityFields[type].map(field =>
            customEntityTypes[type].fields && customEntityTypes[type].fields[field.name]
                ? { ...field, ...customEntityTypes[type].fields[field.name] }
                : field
        ),
    }
})
//export const getTypeInfo = entity =>
//    entity.type === "node" ? entityTypes[entity.bundle] : entityTypes[entity.type]
export const getType = entity => (entity.type === "node" ? entity.bundle : entity.type)
export const getTypeInfo = type => entityTypes[type]
export { entityTypes }
