import React from "react"

import gsap from "gsap"
import { publish, Icon } from "lib"

const onComplete = () => {
    document.body.dataset.website = "loaded"
    publish("website-loaded", true)
}
const introAnimation = () => {
    const w = window.innerWidth
    const h = window.innerHeight
    const portrait = h > w
    const t = gsap.timeline()
    t.pause()
    /*t.set("[type-intro]", {
        background: "#111",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
    })*/

    t.to("[type-intro]", { background: "#111", duration: 0.5 })
    t.to(".intro-logo", { opacity: 1, duration: 0.5 }, 0)
    t.fromTo(
        ".intro-logo #bg-gradient",
        { attr: { gradientTransform: "translate(-1,0)" } },
        { attr: { gradientTransform: "translate(1,0)" }, duration: 4 },
        0.5
    )
    /*t.to(
        ".intro-logo #bg-gradient",
        { attr: { gradientTransform: "translate(1,0)" }, duration: 2 },
        3
    )*/
    t.to(".intro-slogan", { x: portrait ? "-300vw" : "-200vw", duration: 4.5 }, 2.5)
    t.to(".introbg", { opacity: 1, duration: 4 }, 3)
    //t.to(".intro-bg", { opacity: 1, duration: 2 }, 7)
    //t.to(".intro-bg-over", { opacity: 0.9, duration: 2 }, 7)
    t.to(".intro-title div", { y: 0, duration: 1, ease: "expo.out", onComplete }, 5)
    //t.set("[type-intro]", { position: "relative" })

    t.play()
}

const Intro = () => {
    //const [websiteLoaded] = useSubscription("website-loaded")

    React.useEffect(introAnimation, [])
    //if (websiteLoaded) return null
    return (
        <>
            <div className="introbg">
                <video autoPlay loop muted playsInline>
                    <source src="/img/alumeta-4.mp4" type="video/mp4" />
                </video>
            </div>
            <Icon name="LoadingLogo" className="intro-logo" />
            <div className="intro-slogan">
                The <span>future</span> is here
            </div>
            <div className="intro-title">
                <div>
                    ALU
                    <br />
                    META
                    <br />
                    SISTEM
                </div>
            </div>
        </>
    )
}
export default Intro
/*
  <div className="intro-bg" />
  <div className="intro-bg-over" />
*/
