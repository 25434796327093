import React from "react"
import { Entity } from "lib"
import useRollTypes from "./rollTypes"

const RollType = ({ item, dispatch }) => {
    const info = Entity.getInfo(item)
    const handleRollClick = () => {
        dispatch({ type: "select-roll", roll: item })
    }
    return (
        <div className="roll-type" onClick={handleRollClick}>
            <div>{info.getLabel(item)}</div>
        </div>
    )
}

const RollList = ({ state, dispatch }) => {
    const rollTypes = useRollTypes()

    return (
        <div className="roll-types">
            {rollTypes &&
                rollTypes.map((group, i) => (
                    <div key={i} className="group">
                        {group.map((item, i) => (
                            <div key={i} className="item">
                                <RollType item={item} dispatch={dispatch} />
                            </div>
                        ))}
                    </div>
                ))}
        </div>
    )
}
export default RollList
