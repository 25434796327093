import React from "react"
import { realPath } from "../../util"

const Vid = ({ domRef, info, value, ...props }) => {
    if (!value) return null
    const { videoProps, ...rest } = props
    return (
        <div ref={domRef} {...rest}>
            <video controls playsInline {...videoProps}>
                {value.map((v, i) => (
                    <source key={i} src={realPath(v.url)} type="video/mp4" />
                ))}
            </video>
        </div>
    )
}

export default Vid
