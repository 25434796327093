import React from "react"
import { useQuery } from "lib"

const rollTypesQuery = {
    collection: "roll_type",
    query: { active: 1 },
    order: { gauge: 1, weight: 1 },
}
const rollTypesConfig = {}
const useRollTypes = () => {
    const [data] = useQuery(rollTypesQuery, rollTypesConfig)
    const types = React.useMemo(() => {
        if (!data) return null
        //console.log(data)
        const m = data.reduce((acc, item) => {
            const width = item.width || 0
            const gauge = item.gauge || 0
            if (width === 0) {
                if (!acc[0]) acc[0] = []
                acc[0].push(item)
            } else {
                if (!acc[gauge]) acc[gauge] = []
                acc[gauge].push(item)
            }
            return acc
        }, {})
        return Object.keys(m)
            .sort()
            .map(key => m[key])
    }, [data])
    return types
}

export default useRollTypes
