//import { entityFields } from "./entityFields"
import { fieldTypes } from "./fieldTypes"
import { pathFromField } from "./util"
import { getType, getTypeInfo } from "./types"

const cache = new WeakMap()

const _buildFieldInfoRec = fieldInfo => {
    if (!fieldInfo || !fieldTypes[fieldInfo.type] || fieldInfo.primitive) return fieldInfo

    const { name, type, primitive, typeName, ...primitiveFieldInfo } = fieldTypes[fieldInfo.type]
        .primitive
        ? fieldTypes[fieldInfo.type]
        : _buildFieldInfoRec(fieldTypes[fieldInfo.type])
    const info = {
        ...primitiveFieldInfo,
        ...fieldInfo,
        type,
        typeName: fieldInfo.typeName || fieldInfo.type,
        settings: {},
    }
    if (primitiveFieldInfo.settings)
        primitiveFieldInfo.settings.forEach(item => {
            info.settings[item.name] = item
        })
    if (fieldTypes[fieldInfo.type] && fieldTypes[fieldInfo.type].settings)
        fieldTypes[fieldInfo.type].settings.forEach(item => {
            info.settings[item.name] = item
        })
    info.settings = Object.values(info.settings)
    return info
}

const buildFieldInfo = (fieldInfo, value) => {
    //console.log("buildFieldInfo", fieldInfo, value)
    const info = _buildFieldInfoRec(fieldInfo)
    //console.log(info)
    if (!info) return fieldInfo

    if (value && value._e) {
        if (!info.fields) info.fields = []
        let extraFields = []
        Object.keys(value._e).forEach(fieldName => {
            let exist = false
            info.fields = info.fields.map(field => {
                if (field.name === fieldName) {
                    exist = true
                    return { ...field, ...value._e[fieldName] }
                }
                return field
            })
            if (!exist)
                extraFields.push(
                    buildFieldInfo({
                        ...value._e[fieldName],
                        name: fieldName,
                        typeName: value._e[fieldName].type,
                    })
                )
        })
        info.fields = [...info.fields, ...extraFields]
    }
    //console.log(fieldInfo, value, info)
    return info
}
const _fieldInfoRecursive = (parent, parentFieldInfo, path, pathIndex) => {
    //console.log(parent, parentFieldInfo, path, pathIndex)
    switch (parentFieldInfo.type) {
        case "list":
            if (Number.isInteger(path[pathIndex])) {
                const listIndex = path[pathIndex]
                const item = parent ? parent[listIndex] : null
                const itemFieldInfo = buildFieldInfo(
                    {
                        type: parentFieldInfo.items,
                        ...(parentFieldInfo._items && parentFieldInfo._items[listIndex]
                            ? parentFieldInfo._items[listIndex]
                            : []),
                    },
                    item
                )
                if (pathIndex === path.length - 1) return itemFieldInfo
                return _fieldInfoRecursive(item, itemFieldInfo, path, pathIndex + 1)
            } else {
                const itemsFieldInfo = buildFieldInfo({ type: parentFieldInfo.items })
                const item = parent ? parent[0] : null
                return _fieldInfoRecursive(item, itemsFieldInfo, path, pathIndex + 1)
            }
        case "obj": {
            const fields = parentFieldInfo.fields.filter(f => f.name === path[pathIndex])
            if (fields.length === 0) return null
            const child = parent ? parent[path[pathIndex]] : null
            const fieldInfo = buildFieldInfo(fields[0], child)
            if (pathIndex === path.length - 1) return fieldInfo
            return _fieldInfoRecursive(child, fieldInfo, path, pathIndex + 1)
        }
        default:
            return null
    }
}
export const resetInfo = entity => {
    if (cache.has(entity._id)) cache.delete(entity._id)
}
export const getInfo = entity => {
    if (cache.has(entity._id)) return cache.get(entity._id)

    const typeInfo = getTypeInfo(getType(entity))
    if (!typeInfo) return null
    const info = {
        ...typeInfo,
        fieldInfo: {},
        defaultFields: typeInfo.fields.map(f => f.name),
        fields: Object.keys(entity._e || {}).reduce(
            (acc, name) => [...acc, buildFieldInfo(entity._e[name], entity[name])],
            typeInfo.fields || []
        ),
    }
    cache.set(entity._id, info)
    return info
}

export const getFields = entity => {
    const info = getInfo(entity)
    return info ? info.fields : []
}
export const getFieldInfo = (parent, field, parentInfo) => {
    if (!field) return null
    if (!parentInfo) {
        // parent is entity
        if (!parent) return null
        const info = getInfo(parent)
        if (info.fieldInfo[field]) return info.fieldInfo[field]
        const fieldInfo = _fieldInfoRecursive(
            parent,
            { type: "obj", fields: info.fields },
            pathFromField(field),
            0
        )
        info.fieldInfo[field] = fieldInfo
        return fieldInfo
    }
    const fieldInfo = _fieldInfoRecursive(parent, parentInfo, pathFromField(field), 0)
    return fieldInfo
}
export const getCollection = entity => {
    const info = getInfo(entity)
    return info ? info.collection : null
}
