import React, { useRef } from "react"
import { LazyImage, useScreen } from "lib"

const O = {
    landscape: "l",
    portrait: "p",
}
const BlockRenderer = props => {
    const { domRef, info, value, id, className, style, ...other } = props
    const fieldInfo = info.fieldInfo
    const screen = useScreen()
    const bgRef = useRef()
    //const blockInnerRef = useRef()
    const blockInnerStyle = React.useMemo(() => {
        if (
            fieldInfo.p &&
            fieldInfo.bg &&
            fieldInfo.bg.length > 0 &&
            fieldInfo.bg[0].w > 0 &&
            fieldInfo.bg[0].h > 0
        ) {
            let paddingTop = (fieldInfo.bg[0].h / fieldInfo.bg[0].w) * 100 + "%"

            if (fieldInfo.bg[0].srcset) {
                const srcset = fieldInfo.bg[0].srcset.filter(
                    item =>
                        (!item.mq || item.mq.length === 0 || item.mq.indexOf(screen.MQ) >= 0) &&
                        (!item.o || item.o.length === 0 || item.o.indexOf(O[screen.O]) >= 0) &&
                        (!item.r || item.r.length === 0 || item.r.indexOf(screen.RATIO) >= 0)
                )
                if (srcset.length > 0 && srcset[0].h > 0 && srcset[0].w > 0) {
                    paddingTop = (srcset[0].h / srcset[0].w) * 100 + "%"
                }
            }

            return {
                height: 0,
                paddingTop,
            }
        }
        return {}
    }, [fieldInfo, screen])

    //if (!value) return <div ref={domRef} />

    let classes = []
    if (className) classes.push(className)

    let blockType = []
    if (
        fieldInfo.p &&
        fieldInfo.bg &&
        fieldInfo.bg.length > 0 &&
        fieldInfo.bg[0].w > 0 &&
        fieldInfo.bg[0].h > 0
    ) {
        //classes.push("block-proportional")
        blockType.push("proportional")
    }
    if (fieldInfo.f) {
        //classes.push("block-fullfixed")
        blockType.push("fullfixed")
    }
    if (info.fieldInfo._o) {
        //classes.push("horizontal")
        blockType.push("horizontal")
    }
    const blockStyle = { ...style }
    //if (fieldInfo.bgColor) blockStyle.background = fieldInfo.bgColor
    if (fieldInfo.fields.length === 0) classes.push("empty")

    const blockAttributes = {}
    if (blockType.length > 0) blockAttributes["block-type"] = blockType.join(" ")
    if (classes.length > 0) blockAttributes.className = classes.join(" ")

    //if (blockType.length)
    return (
        <div ref={domRef} id={id} style={blockStyle} {...other} {...blockAttributes}>
            {fieldInfo.bg &&
                fieldInfo.bg.length > 0 &&
                fieldInfo.bg.map((bg, i) => (
                    <LazyImage domRef={bgRef} key={i} className="bg" bg={true} src={bg} />
                ))}
            <div style={blockInnerStyle} block-inner="">
                <div block-content="">{props.children}</div>
            </div>
        </div>
    )
}
export default BlockRenderer
