import React from "react"
import { Field, Icon } from "lib"

const IconRenderer = ({ domRef, info, value, ...props }) => {
    if (!value) return null

    return (
        <div ref={domRef} {...props}>
            {value.icon && <Icon name={value.icon} />}
            <Field info={info} field="text" noadmin />
        </div>
    )
}
export default React.memo(IconRenderer)
