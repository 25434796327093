import React from "react"
import { Region } from "lib"
import PageLayout from "./PageLayout"

export const DefaultLayout = ({ entity, language }) => (
    <Region entity={entity} language={language} dynamic />
)

export const nodeLayout = {
    page: PageLayout,
    NotFound: PageLayout,
}
